var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isDefaultButton && _vm.isNotFromContact(_vm.data)
    ? _c(
        "div",
        {
          class: [
            "imelink-contact",
            { "imelink-contact--notice": _vm.data.isNotice },
            (_vm.isSelected || _vm.isActive) && "imelink-contact--selected",
            !_vm.noHover && "imelink-contact--hover",
            { "imelink-contact--self": _vm.data.isGroup },
            { gray: _vm.data.isDelete == 2 || _vm.data.isDelete == 3 }
          ],
          attrs: {
            draggable:
              _vm.$store.getters.userInfo.id == _vm.data.id ? false : true
          },
          on: {
            click: function($event) {
              return _vm._handleClick($event, _vm.data)
            },
            dragstart: function($event) {
              return _vm.drag($event, _vm.data)
            },
            dragend: _vm.dragend
          }
        },
        [
          _vm.groupOwnerId !== _vm.data.id && _vm.transferringOwner
            ? _c("span", {
                class: [
                  "transferringOwner",
                  `el-icon--check-circle${
                    _vm.transferringOwnerSelect ? "-fill" : ""
                  }`
                ],
                style: {
                  background: "transparent",
                  color: _vm.transferringOwnerSelect ? "#59be68" : "#cdcfce",
                  background: _vm.transferringOwnerSelect
                    ? "#f6f6f6"
                    : "#FFFFFF"
                }
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "imelink-contact__avatar",
              attrs: { ondragstart: "return false" }
            },
            [
              _c(
                "el-badge",
                {
                  attrs: {
                    value: _vm.data.unread,
                    hidden:
                      _vm.data.unread <= 0 ||
                      _vm.control.currentTab != "records",
                    max: 99
                  }
                },
                [
                  _vm.control.searchMode &&
                  _vm.data.avatar &&
                  _vm.data.avatar.indexOf("file.") == 0
                    ? [
                        _c("div", { staticClass: "avatarIcon file" }, [
                          _c("i", { staticClass: "el-icon-folder" }),
                          _c("span", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.data.avatar
                                    .slice(
                                      _vm.data.avatar.indexOf(".") + 1,
                                      _vm.data.avatar.indexOf(".") + 4
                                    )
                                    .toUpperCase()
                                ) +
                                "\n          "
                            )
                          ])
                        ])
                      ]
                    : _vm._e(),
                  _c("avatar", {
                    attrs: {
                      src: _vm.data.avatar
                        ? _vm.formartImageUrl(
                            _vm.data.avatar,
                            _vm.data.isDelete
                          )
                        : "",
                      "error-icon": _vm.data.isGroup ? "el-icon--group1" : "",
                      size: _vm.avatarSize,
                      "border-radius": _vm.data.searchFile ? "4px" : "50%",
                      isFrozen: _vm.data.isFrozen
                    },
                    nativeOn: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.data.access_pattern == 5 ||
                        _vm.data.access_pattern == 6 ||
                        _vm.notAllowClickAvatar
                          ? ""
                          : _vm._hadnleAvatarClick()
                      }
                    }
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "imelink-contact__label",
              style: { fontSize: _vm.nameSize }
            },
            [
              _c(
                "div",
                {
                  staticClass: "imelink-contact__tag",
                  style: { "z-index": 1 }
                },
                [
                  _vm.groupOwnerId == _vm.data.id
                    ? _c("span", [
                        _c("i", {
                          staticClass:
                            "imelink-contact__tag-item el-icon-user-solid"
                        })
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "bottom-start",
                    trigger: "hover",
                    "popper-class": "tagsPopover",
                    "visible-arrow": false,
                    "popper-options": {
                      removeOnDestroy: true,
                      gpuAcceleration: true
                    },
                    disabled: !!_vm.data.isNotice,
                    "close-delay": 20
                  }
                },
                [
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "name inner" }, [
                      _vm._v(_vm._s(_vm.data.displayName))
                    ]),
                    !_vm.closeTag &&
                    _vm.data.remarks &&
                    _vm.data.remarks.length != 0
                      ? _c(
                          "div",
                          {
                            staticClass: "tags inner",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.openRemark(_vm.data)
                              }
                            }
                          },
                          [
                            _vm._l(_vm.data.remarks, function(text) {
                              return [_vm._v(_vm._s(`#${text} `))]
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "name_time_line",
                      attrs: { slot: "reference" },
                      slot: "reference"
                    },
                    [
                      _c(
                        "p",
                        {
                          class: {
                            "imelink-contact__name": true,
                            lage:
                              this.$store.state.imStore.IMWindowSize === "full"
                          }
                        },
                        [
                          _vm.data.vipStatus
                            ? _c(
                                "span",
                                { staticClass: "xvipName" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "vipDisplayName",
                                      style: {
                                        maxWidth:
                                          _vm.data.remarks &&
                                          _vm.data.remarks.length != 0
                                            ? "50px"
                                            : ""
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.data.displayName))]
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: _vm.$t("paidMember"),
                                        placement: "bottom",
                                        "popper-class": "me-tooltip-effect"
                                      }
                                    },
                                    [
                                      _c("el-image", {
                                        staticClass: "vipIcon",
                                        attrs: {
                                          src: require("@/assets/images/pay/masonry.png"),
                                          fit: "fill"
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.handleClickVip(
                                              _vm.data.id
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c("span", {
                                staticClass: "imelink-contact__commonName",
                                style: {
                                  "max-width":
                                    (_vm.data.remarks &&
                                      _vm.data.remarks.length != 0) ||
                                    _vm.data.collected
                                      ? "calc(100% - 30px)"
                                      : "100%"
                                },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.lightHeight(
                                      _vm.replacAlabel(_vm.data.displayName)
                                    )
                                  )
                                }
                              }),
                          _vm.data.groupOwnerId == this.control.user.id &&
                          _vm.control.needShowGroupList
                            ? _c("i", {
                                class: {
                                  "imelink-contact__group-owner": true,
                                  "el-icon-user-solid": true
                                }
                              })
                            : _vm._e(),
                          !_vm.closeTag &&
                          _vm.data.remarks &&
                          _vm.data.remarks.length != 0
                            ? [
                                _vm.IMWindowSize === "full"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "tagTips",
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.openRemark(_vm.data)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", [_vm._v("#")]),
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              `${_vm.data.remarks[0]}${
                                                _vm.data.remarks.length > 1
                                                  ? "..."
                                                  : ""
                                              }`
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  : _vm.IMWindowSize !== "full"
                                  ? _c("i", { staticClass: "tagTips" }, [
                                      _vm._v(
                                        "#" +
                                          _vm._s(
                                            typeof _vm.data.remarks[0] ==
                                              "string"
                                              ? _vm.data.remarks[0].charAt(0)
                                              : ""
                                          ) +
                                          "..."
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm.data.lastMessageTime && !_vm.isCheckbox
                        ? _c(
                            "span",
                            { staticClass: "imelink-contact__lasttime" },
                            [_c("small", [_vm._v(_vm._s(_vm.lastTime))])]
                          )
                        : _vm._e()
                    ]
                  )
                ]
              ),
              _c("div", { staticClass: "message_box" }, [
                _vm.stillInTheGroup() && _vm.data.collected
                  ? _c("div", { staticClass: "collected" }, [
                      _c("span", {
                        staticClass: "iconfont_Me icon-a-lujing13404",
                        staticStyle: {
                          "font-size": "14px",
                          "margin-left": "2px",
                          color: "#ffb320"
                        },
                        style: {
                          marginRight:
                            _vm.data.groupOwnerId == this.control.user.id &&
                            _vm.control.needShowGroupList
                              ? "20px"
                              : ""
                        }
                      })
                    ])
                  : _vm._e(),
                _vm.data.isNotice && _vm.data.isNotice == "transfer_message"
                  ? _c(
                      "p",
                      {
                        staticClass: "imelink-contact__lastmessage",
                        staticStyle: { "margin-right": "40px" }
                      },
                      [
                        _c("small", {
                          domProps: {
                            innerHTML: _vm._s(_vm.lastMessageContent)
                          }
                        })
                      ]
                    )
                  : _vm._e(),
                _vm.data.lastMessageType &&
                !_vm.data.isNotice &&
                !_vm.data.isReview
                  ? _c(
                      "p",
                      {
                        staticClass: "imelink-contact__lastmessage",
                        staticStyle: { "margin-right": "40px" }
                      },
                      [
                        _vm.isCheckAt
                          ? _c(
                              "span",
                              {
                                staticClass: "atClass",
                                staticStyle: { color: "red" }
                              },
                              [
                                _vm._v(
                                  "[@" +
                                    _vm._s(this.control.user.displayName) +
                                    "]"
                                )
                              ]
                            )
                          : _vm._e(),
                        _c("small", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.data.lastMessageType == "text" ||
                                _vm.data.lastMessageType == "webUrl",
                              expression:
                                "\n            data.lastMessageType == 'text' || data.lastMessageType == 'webUrl'\n          "
                            }
                          ],
                          domProps: {
                            innerHTML: _vm._s(_vm.lastMessageContent)
                          }
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.data.isDelete == 2 ||
                                  _vm.data.isDelete == 3,
                                expression:
                                  "data.isDelete == 2 || data.isDelete == 3"
                              }
                            ],
                            staticStyle: {
                              position: "absolute",
                              right: "10px",
                              bottom: "10px"
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/icon/group-remove.png")
                              }
                            })
                          ]
                        ),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.data.lastMessageType != "text" &&
                                !_vm.data.lastMessageContent,
                              expression:
                                "data.lastMessageType != 'text' && !data.lastMessageContent"
                            }
                          ],
                          class: `el-icon-${
                            _vm.messageTypeIcon[_vm.data.lastMessageType]
                          }`
                        }),
                        _c(
                          "small",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.data.lastMessageType != "text" &&
                                  _vm.data.lastMessageType != "webUrl" &&
                                  _vm.data.lastMessageType != "notice" &&
                                  _vm.data.lastMessageContent,
                                expression:
                                  "\n            data.lastMessageType != 'text' &&\n              data.lastMessageType != 'webUrl' &&\n              data.lastMessageType != 'notice' &&\n              data.lastMessageContent\n          "
                              }
                            ]
                          },
                          [
                            _c("i", {
                              staticClass: "iconfont_Me icon-a-lujing13297"
                            })
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "imelink-contact__isInMeeting" },
                  [_vm._t("isInMeeting")],
                  2
                )
              ]),
              _c("span", {
                staticClass: "imelink-contact__des",
                domProps: {
                  innerHTML: _vm._s(_vm.lightHeight(_vm.data.description))
                }
              }),
              _vm.groupOwnerId &&
              _vm.data.id ==
                _vm.control.getCurrentGroupInfo().translate.proxyUserId
                ? _c("img", {
                    staticStyle: {
                      position: "absolute",
                      right: "10px",
                      top: "40%",
                      width: "18px"
                    },
                    attrs: { src: require("assets/images/icon/translate.png") }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isCheckbox && !_vm.data.isCustomerGroup,
                expression: "isCheckbox && !data.isCustomerGroup"
              }
            ],
            class: [
              "imelink-contact__success",
              `el-icon--check-circle${_vm.isSelected ? "-fill" : ""}`
            ],
            style: {
              background: "transparent"
            }
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isCheckbox,
                  expression: "!isCheckbox"
                }
              ],
              staticClass: "imelink-contact__action"
            },
            [_vm._t("action")],
            2
          )
        ]
      )
    : _vm.data.isBlackListButton && !_vm.userClickAddToTheContact
    ? _c(
        "div",
        {
          staticClass: "operationButton",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.showBlacklist.apply(null, arguments)
            }
          }
        },
        [
          _c("div", { staticClass: "leftBox" }, [
            _vm._m(0),
            _c("div", { staticClass: "blackListText" }, [
              _vm._v(_vm._s(_vm.$t("blackList")))
            ])
          ]),
          _c(
            "div",
            { staticClass: "rightBox" },
            [
              _c("el-image", {
                staticStyle: { width: "100%", height: "100%" },
                attrs: {
                  src: require("../../../assets/images/im/nextView.png"),
                  fit: "cover"
                }
              })
            ],
            1
          )
        ]
      )
    : _vm.data.isContactsButton
    ? _c(
        "div",
        {
          staticClass: "operationButton",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.showContacts.apply(null, arguments)
            }
          }
        },
        [
          _c("div", { staticClass: "leftBox" }, [
            _vm._m(1),
            _c("div", { staticClass: "blackListText" }, [
              _vm._v(_vm._s(_vm.$t("contacts")))
            ])
          ]),
          _c(
            "div",
            { staticClass: "rightBox" },
            [
              _c("el-image", {
                staticStyle: { width: "100%", height: "100%" },
                attrs: {
                  src: require("../../../assets/images/im/nextView.png"),
                  fit: "cover"
                }
              })
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "blackListIcon" }, [
      _c("span", { staticClass: "iconStyle iconfont_Me icon-a-zu9816" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "blackListIcon" }, [
      _c("span", { staticClass: "iconStyle iconfont_Me icon-a-Group34993" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }