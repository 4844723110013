<template>
  <div
    :class="[
      'imelink-contact',
      { 'imelink-contact--notice': data.isNotice },
      (isSelected || isActive) && 'imelink-contact--selected',
      !noHover && 'imelink-contact--hover',
      { 'imelink-contact--self': data.isGroup },
      { gray: data.isDelete == 2 || data.isDelete == 3 },
    ]"
    v-if="isDefaultButton && isNotFromContact(data)"
    @click="_handleClick($event, data)"
    :draggable="$store.getters.userInfo.id == data.id ? false : true"
    @dragstart="drag($event, data)"
    @dragend="dragend"
  >
    <!-- 转让群组的选择按钮 -->
    <span
      :class="[
        'transferringOwner',
        `el-icon--check-circle${transferringOwnerSelect ? '-fill' : ''}`,
      ]"
      v-if="groupOwnerId !== data.id && transferringOwner"
      :style="{
        background: 'transparent',
        color: transferringOwnerSelect ? '#59be68' : '#cdcfce',
        background: transferringOwnerSelect ? '#f6f6f6' : '#FFFFFF',
      }"
    >
    </span>
    <div class="imelink-contact__avatar" ondragstart="return false">
      <el-badge
        :value="data.unread"
        :hidden="data.unread <= 0 || control.currentTab != 'records'"
        :max="99"
      >
        <template
          v-if="
            control.searchMode &&
              data.avatar &&
              data.avatar.indexOf('file.') == 0
          "
        >
          <div class="avatarIcon file">
            <i class="el-icon-folder"></i>
            <span>
              {{
                data.avatar
                  .slice(
                    data.avatar.indexOf(".") + 1,
                    data.avatar.indexOf(".") + 4
                  )
                  .toUpperCase()
              }}
            </span>
          </div>
        </template>
        <avatar
          :src="data.avatar ? formartImageUrl(data.avatar, data.isDelete) : ''"
          :error-icon="data.isGroup ? 'el-icon--group1' : ''"
          :size="avatarSize"
          :border-radius="data.searchFile ? '4px' : '50%'"
          @click.native.stop="
            data.access_pattern == 5 ||
            data.access_pattern == 6 ||
            notAllowClickAvatar
              ? ''
              : _hadnleAvatarClick()
          "
          :isFrozen="data.isFrozen"
        ></avatar>
      </el-badge>
    </div>
    <div class="imelink-contact__label" :style="{ fontSize: nameSize }">
      <div class="imelink-contact__tag" :style="{ 'z-index': 1 }">
        <span v-if="groupOwnerId == data.id">
          <i class="imelink-contact__tag-item el-icon-user-solid"></i>
        </span>
      </div>
      <el-popover
        placement="bottom-start"
        trigger="hover"
        popper-class="tagsPopover"
        :visible-arrow="false"
        :popper-options="{
          removeOnDestroy: true,
          gpuAcceleration: true,
        }"
        :disabled="!!data.isNotice"
        :close-delay="20"
      >
        <div class="content">
          <div class="name inner">{{ data.displayName }}</div>
          <div
            class="tags inner"
            v-if="!closeTag && data.remarks && data.remarks.length != 0"
            @click.stop="openRemark(data)"
          >
            <template v-for="text in data.remarks">{{ `#${text} ` }}</template>
          </div>
        </div>
        <div class="name_time_line" slot="reference">
          <p
            :class="{
              'imelink-contact__name': true,
              lage: this.$store.state.imStore.IMWindowSize === 'full',
            }"
          >
            <span v-if="data.vipStatus" class="xvipName">
              <span
                class="vipDisplayName"
                :style="{
                  maxWidth:
                    data.remarks && data.remarks.length != 0 ? '50px' : '',
                }"
                >{{ data.displayName }}</span
              >
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('paidMember')"
                placement="bottom"
                popper-class="me-tooltip-effect"
              >
                <el-image
                  class="vipIcon"
                  :src="require('@/assets/images/pay/masonry.png')"
                  fit="fill"
                  @click.stop="handleClickVip(data.id)"
                >
                </el-image>
              </el-tooltip>
            </span>
            <span
              v-else
              class="imelink-contact__commonName"
              :style="{
                'max-width':
                  (data.remarks && data.remarks.length != 0) || data.collected
                    ? 'calc(100% - 30px)'
                    : '100%',
              }"
              v-html="lightHeight(replacAlabel(data.displayName))"
            ></span>
            <i
              v-if="
                data.groupOwnerId == this.control.user.id &&
                  control.needShowGroupList
              "
              :class="{
                'imelink-contact__group-owner': true,
                'el-icon-user-solid': true,
              }"
            ></i>
            <template
              v-if="!closeTag && data.remarks && data.remarks.length != 0"
            >
              <span
                class="tagTips"
                @click.stop="openRemark(data)"
                v-if="IMWindowSize === 'full'"
              >
                <i>#</i>
                {{
                  `${data.remarks[0]}${data.remarks.length > 1 ? "..." : ""}`
                }}
              </span>
              <i class="tagTips" v-else-if="IMWindowSize !== 'full'"
                >#{{
                  typeof data.remarks[0] == "string"
                    ? data.remarks[0].charAt(0)
                    : ""
                }}...</i
              >
            </template>
          </p>
          <span
            class="imelink-contact__lasttime"
            v-if="data.lastMessageTime && !isCheckbox"
          >
            <small>{{ lastTime }}</small>
          </span>
        </div>
      </el-popover>
      <div class="message_box">
        <!-- 显示是否已分类图标 -->
        <div class="collected" v-if="stillInTheGroup() && data.collected">
          <span
            class="iconfont_Me icon-a-lujing13404"
            style="font-size: 14px; margin-left: 2px; color: #ffb320"
            :style="{
              marginRight:
                data.groupOwnerId == this.control.user.id &&
                control.needShowGroupList
                  ? '20px'
                  : '',
            }"
          ></span>
        </div>
        <p
          class="imelink-contact__lastmessage"
          style="margin-right: 40px"
          v-if="data.isNotice && data.isNotice == 'transfer_message'"
        >
          <small v-html="lastMessageContent"></small>
        </p>
        <p
          class="imelink-contact__lastmessage"
          style="margin-right: 40px"
          v-if="data.lastMessageType && !data.isNotice && !data.isReview"
        >
          <!-- @信息 -->
          <span v-if="isCheckAt" class="atClass" style="color: red"
            >[@{{ this.control.user.displayName }}]</span
          >
          <!-- 最后一条消息 -->
          <small
            v-show="
              data.lastMessageType == 'text' || data.lastMessageType == 'webUrl'
            "
            v-html="lastMessageContent"
          ></small>
          <!-- 已被违规删除显示 -->
          <span
            v-show="data.isDelete == 2 || data.isDelete == 3"
            style="position: absolute; right: 10px; bottom: 10px"
          >
            <img src="@/assets/images/icon/group-remove.png" />
          </span>
          <i
            v-show="data.lastMessageType != 'text' && !data.lastMessageContent"
            :class="`el-icon-${messageTypeIcon[data.lastMessageType]}`"
          ></i>

          <small
            v-show="
              data.lastMessageType != 'text' &&
                data.lastMessageType != 'webUrl' &&
                data.lastMessageType != 'notice' &&
                data.lastMessageContent
            "
            ><i class="iconfont_Me icon-a-lujing13297"></i
          ></small>
        </p>
        <div class="imelink-contact__isInMeeting">
          <slot name="isInMeeting"></slot>
        </div>
      </div>
      <span
        class="imelink-contact__des"
        v-html="lightHeight(data.description)"
      ></span>
      <img
        v-if="
          groupOwnerId &&
            data.id == control.getCurrentGroupInfo().translate.proxyUserId
        "
        style="position: absolute; right: 10px; top: 40%; width: 18px"
        src="~assets/images/icon/translate.png"
      />
    </div>
    <!-- 展示选择按钮 -->
    <i
      v-show="isCheckbox && !data.isCustomerGroup"
      :class="[
        'imelink-contact__success',
        `el-icon--check-circle${isSelected ? '-fill' : ''}`,
      ]"
      :style="{
        background: 'transparent',
      }"
    ></i>
    <!-- 展示默认插槽 -->
    <div class="imelink-contact__action" v-show="!isCheckbox">
      <slot name="action"></slot>
    </div>
  </div>
  <!-- 这里展示的是黑名单按钮 -->
  <div
    class="operationButton"
    v-else-if="data.isBlackListButton && !userClickAddToTheContact"
    @click.stop="showBlacklist"
  >
    <div class="leftBox">
      <div class="blackListIcon">
        <span class="iconStyle iconfont_Me icon-a-zu9816"></span>
      </div>
      <div class="blackListText">{{ $t("blackList") }}</div>
    </div>
    <div class="rightBox">
      <el-image
        style="width: 100%; height: 100%"
        :src="require('../../../assets/images/im/nextView.png')"
        :fit="'cover'"
      ></el-image>
    </div>
  </div>
  <!-- 这里展示的是通讯录按钮 -->
  <div
    class="operationButton"
    v-else-if="data.isContactsButton"
    @click.stop="showContacts"
  >
    <div class="leftBox">
      <div class="blackListIcon">
        <span class="iconStyle iconfont_Me icon-a-Group34993"></span>
      </div>
      <div class="blackListText">{{ $t("contacts") }}</div>
    </div>
    <div class="rightBox">
      <el-image
        style="width: 100%; height: 100%"
        :src="require('../../../assets/images/im/nextView.png')"
        :fit="'cover'"
      ></el-image>
    </div>
  </div>
</template>

<script>
//创建客服群
import { createCustomerGroup } from "@/api/rest";
import { parseTime } from "utils";
import { isString } from "utils/validate";
const encryptMessageType = "消息加密，请升级为会员查看";
export default {
  name: "Contact",
  props: {
    groupOwnerId: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      required: true,
    },
    closeTag: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "",
    },
    isCheckbox: Boolean,
    defaultSelected: Boolean,
    noHover: Boolean,
    isActive: Boolean,
    transferringOwner: {
      type: Boolean,
      default: false,
    },
    transferringOwnerSelect: {
      type: Boolean,
      default: false,
    },
    avatarSize: {
      type: String,
      default: "36px",
    },
    nameSize: {
      type: String,
      default: "14px",
    },
    type: {
      type: String,
    },
    notAllowClickAvatar: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  inject: ["control"],
  data() {
    this.messageTypeIcon = {
      voice: "-voice",
      video: "-video-fill",
      image: "picture",
      file: "-folder-fill",
      recall: "-replay",
    };
    this.isRead = false;
    return {
      isSelected: this.defaultSelected,
      isShowAt: false,
    };
  },
  computed: {
    IMWindowSize() {
      return this.$store.state.imStore.IMWindowSize;
    },
    isDefaultButton() {
      return !this.data.isBlackListButton && !this.data.isContactsButton;
    },
    lastTime() {
      if (this.data.lastMessageTime) {
        if (this.isToday(parseTime(this.data.lastMessageTime, "{y}-{m}-{d}"))) {
          return parseTime(this.data.lastMessageTime, "{h}:{i}");
        } else {
          return parseTime(this.data.lastMessageTime, "{m}-{d}");
        }
      }
      return "";
    },
    lastMessageContent() {
      if (
        this.data.isEncryptMessage &&
        this.data.lastMessageContent == encryptMessageType
      ) {
        return '<i class="iconfont_Me icon-a-lujing13297"></i>';
      }
      if (
        this.data.lastMessageContent &&
        isString(this.data.lastMessageContent)
      ) {
        return this.data.lastMessageContent
          .replace(/\[!(\w+)\]/gi, `[${this.$t("emoji")}]`)
          .replace(/&nbsp;|<br>/gi, "");
      } else {
        return "";
      }
    },
    // 当前未读的消息列表
    unreadMsgList() {
      return this.$store.state.imStore.unreadMsgList;
    },
    // 判断是否被@
    isCheckAt() {
      if (this.unreadMsgList && this.unreadMsgList[this.data.id]) {
        let isIncludesAt = this.unreadMsgList[this.data.id].some((item) => {
          if (item.text && typeof item.text == "string") {
            return item.text.indexOf("@" + this.control.user.displayName) != -1;
          } else {
            return false;
          }
        });
        return isIncludesAt;
      } else {
        return this.data.at;
      }
    },
    userClickAddToTheContact() {
      return this.$store.state.ipTelephone.userClickAddToTheContact;
    },
  },
  methods: {
    // 用户是否还在群里
    stillInTheGroup() {
      let stillIn = true;
      if (this.data.isGroup && this.data.messageData) {
        // 如果是退出群组，并且退出的是用户自己
        if (
          this.data.messageData.actionType == 4 &&
          this.data.messageData.removeID == this.$store.getters.userInfo.id
        ) {
          stillIn = false;
        }
        if (!this.data.groupOwnerId) {
          stillIn = false;
        }
      }
      return stillIn;
    },
    // 判断是不是从通讯录添加联系人
    isNotFromContact(data) {
      if (
        (data.isCustomerGroup || data.id == this.$store.getters.userInfo.id) &&
        this.userClickAddToTheContact
      ) {
        return false;
      } else {
        return true;
      }
    },
    isJSON(str) {
      if (typeof str == "string") {
        try {
          JSON.parse(str);
          return true;
        } catch (e) {
          return false;
        }
      }
    },
    openRemark(contact) {
      this.control.$refs.contactRemark.open(contact);
    },
    // 展示黑名单列表
    showBlacklist() {
      if (this.type == "friends" && this.userClickAddToTheContact) {
        return;
      }
      this.$emit("showBlacklist");
    },
    // 展示通讯录
    showContacts() {
      this.control.isShowContacts = true;
    },
    replacHTML(str) {
      if (!str) return str;
      return str.replace(/<[^>]*>|<\/[^>]*>/gi, "");
    },
    replacAlabel(str) {
      if (!str) return str;
      return str.replace(/(<\/?a.*?>)|(<\/?span.*?>)/g, "");
    },
    isToday(str) {
      return Date.now() - new Date(str).getTime() < 86400000;
    },
    change(val) {
      this.isSelected = val;
    },
    _hadnleAvatarClick() {
      if (this.data.isUser) {
        this.jumpPage({ name: "personalCenter", params: { id: this.data.id } });
      }
      if (this.data.isGroup) {
        this.routeJump({
          name: "groupCenter",
          params: { id: this.data.id.toString() },
        });
      }
    },
    async _handleClick(e, data) {
      if (
        this.type == "friends" &&
        this.userClickAddToTheContact &&
        !data.isGroup
      ) {
        this.$store.commit("setContactDialogData", {
          dialog: true,
          type: "add",
          data: {
            profilePhoto: data.avatar,
            name: data.displayName,
            phone: this.userClickAddToTheContact.phone,
            contactsProviderId: data.id,
          },
        });
        return;
      }
      // 如果是被冻结的账号
      if (data.isFrozen) {
        return;
      }
      // 如果是被注销或删除的账号
      if (data.isDelete == 2 || data.isDelete == 3) {
        return;
      }
      if (this.transferringOwner) {
        this.$emit("selectNewGroupOwner", data);
        return;
      }
      this.control.isShowContacts = false;
      //从好友列表点击客服，跳入聊天列表，并创建新的客服群，并主动推送一些配置的问题
      if (this.type == "friends" && data.isCustomerGroup) {
        // 1.创建一个客服群
        const TOKEN = "BF03DF03D6925CDsf555828633A096D0C234";
        const result = await createCustomerGroup(TOKEN).catch(() => {});
        if (result.code == 200) {
          const { groupHeader, groupId, groupName } = result.data.data;
          // 2.打开IM
          setTimeout(() => {
            this.$openIM({
              id: groupId,
              displayName: groupName,
              avatar: groupHeader,
              isGroup: true,
            });
          }, 500);
        }
        return;
      }

      this.isRead = true;
      // 点击后情况缓存的数据
      data.at = 0;
      if (this.unreadMsgList) {
        let unreadList = null;
        try {
          unreadList = _.cloneDeep(this.unreadMsgList);
        } catch (error) {
          unreadList = this.unreadMsgList;
        }
        unreadList[this.data.id] = [];
        this.$store.commit("setUnreadMsgList", unreadList);
      }
      this.$store.commit("setSelectedMeetingId", data.id);
      this.$emit("click", e);
      if (this.isCheckbox) {
        if (this.data.isCustomerGroup) {
          return;
        }
        // 判断是否是群友，是的话就禁止点击(如果是转发的的话，可以选择自己)
        if (
          this.data.isGroup ||
          Array.from(this.$el.classList).indexOf("imelink-contact--self") === -1
        ) {
          this.isSelected = !this.isSelected;
          this.$emit("change-selected", this.isSelected, this.data);
        }
      }
    },
    // 拖拽
    drag(ev, data) {
      if (
        ev.target.parentNode.className != "ownerBlock" &&
        ev.target.parentNode.className != "membersView"
      ) {
        return;
      }
      this.id = data.id;
      this.dom = ev.currentTarget.cloneNode(true);
      ev.dataTransfer.setData("text", JSON.stringify(data));
      document.querySelector(".imelink-editor__main").style.background =
        "#f7fffa";
    },
    dragend() {
      document.querySelector(".imelink-editor__main").style.background = "#fff";
    },
  },
};
</script>
<style lang="stylus" scoped>
.gray {
  filter: gray;
}
.transferringOwner
  flex-shrink: 0;
  margin-right: 10px;
  font-size: 22px;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  display: inline-block;
  overflow: hidden;
  border-radius: 50%;
.imelink-contact__label
  box-sizing: border-box;
  padding-left: 10px;
  // width: 100%;
  flex: 1;
  overflow: hidden;
  border-radius: 40px;
  .name_time_line
    display: flex;
    flex-flow: row;
    overflow: hidden;
    max-width: 100%;
    justify-content: space-between;
    .imelink-contact__lasttime
      min-width: 40px;
      flex-shrink: 0;
    .imelink-contact__name
      flex: 1;
      min-width: 0;
      font-size: 14px;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .xvipName
        max-width: 130px;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        .vipDisplayName
          max-width: 80px;
          overflow hidden;
          text-overflow: ellipsis
          white-space: nowrap;
        .vipIcon
          width: 17px;
          height: 14px;
          margin-left: 3px;
      .imelink-contact__commonName
        height: 20px;
        line-height: 20px;
        margin-right: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      .tagTips
        color #33CC66;
        font-size: 14px;
        margin-left: 3px;
        flex: 1;
        min-width: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      .imelink-contact__remark
        color: #333;
        margin-right: 4px;
        max-width: 54px;
        border-radius: 20px;
        line-height: 20px;
        height: 20px;
        background: #fdb041;
        color: #fff;
        border: none !important;
        overflow hidden;
        text-overflow: ellipsis
        white-space: nowrap;
.operationButton
  width: 100%;
  height: 64px;
  box-sizing: border-box;
  padding: 0 10px;
  border-top: 1px solid #F0F0F0;
  border-bottom: 1px solid #F0F0F0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  &:hover
    background: #ebebeb;
  .leftBox
    flex: 1;
    height: 36px;
    min-width: 0;
    overflow: hidden;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .blackListIcon
      width: 26px;
      height: 26px;
      overflow: hidden;
      margin-right: 12px;
      .iconStyle
        font-size: 26px;
        color: #000000;
    .blackListText
      color: #333333;
      font-size: 14px;
      line-height: 20px;
  .rightBox
    width: 36px;
    height: 36px;
    flex-shrink: 0;
</style>
<style lang="stylus">
@import '~styles/utils/index';

hover-background = #ebebeb;

.imelink-container--selection .imelink-contact--notice {
  display: none;
}
.tagsPopover
  min-width: 0;
  padding: 0;
  line-height: 16px;
  font-size: 12px;
  background: #FFFCE5;
  border: 1px solid #000000;
  border-radius: 0;
  color: #000000;
  padding: 5px 3px;
  .content
    overflow hidden;
    min-width: 0;
    max-width: 300px;
    box-sizing: border-box;
    .inner
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    .name
      cursor: default;
    .tags
      cursor: pointer;
      color: #33CC66;
.avatarIcon {
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(233, 233, 233);
  position: relative;
  white-space: nowrap;

  &.file {
    background: #e4f0fc;
    border-radius: 3px;
  }

  .el-icon--line-video {
    font-size: 16px;
    color: #c2c2c2;
  }

  span {
    font-size: 12px;
    position: absolute;
    // font-weight: 600;
    // letter-spacing: -0.5px;
    color: #1a92ed;
  }

  .el-icon-folder {
    &:before {
      font-size: 36px;
      color: #1a92ed;
    }
  }
}

+b(imelink-contact) {
  padding: 12px 10px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
  position: relative;

  .el-badge__content {
    transition: none !important;
    line-height: 14px;
    padding: 0 5px;
  }

  .transferringOwner {
    .el-checkbox {
      .el-checkbox__inner {
        border-radius: 50%;
        width: 18px;
        height: 18px;
        &::after {
          left: 6px;
          width: 4px;
          height: 10px;
        }
      }
    }
  }
  +m(hover) {
    &:hover {
      background: hover-background;
    }
  }

  +e(lasttime) {
    // position: absolute;
    right: 10px;
    top: 8px;
    color: #888;
  }

  .message_box {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .collected {
      right: 10px;
      bottom: 0;
      position: absolute;
    }
  }

  +e(lastmessage) {
    color: #999;
    ellipsis();
    height: 20px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;

    small {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    img {
      margin: 0 0.15rem;
    }
  }

  +e(tag) {
    position: absolute;
    left: 4px;
    bottom: 6px;
    transform: scale(0.8);
  }

  +e(tag-item) {
    border-radius: 50%;
    color: #fff;
    padding: 2px;
    background: color-primary;
  }

  +e(success) {
    position: absolute;
    top: 50%;
    margin-top: -13px;
    right: 10px;
    font-size: 22px;
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    display: inline-block;
    overflow: hidden;
    color: #cdcfce;
    border-radius: 50%;
    background: #fff;
  }

  +e(group-owner) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    color: #fff;
    background: #52bd68;
    border-radius: 50%;
    padding: 3px;
    font-size: 12px;
  }

  +m(selected) {
    background: hover-background;

    +b(imelink-contact) {
      +e(success) {
        color: #59be68;
        background: #f6f6f6;
      }
    }
  }

  +e(des) {
    display: block;
    font-size: 12px;
    color: #999;
    ellipsis();
  }

  +e(action) {
    position-center-y();
    right: 0;
    font-size: 20px;
    display: none;

    [class^=el-icon-] {
      border-radius: 50%;
      margin: 10px;
      background: #ebebeb;
    }
  }

  &:hover {
    +b(imelink-contact) {
      +e(action) {
        display: block;
      }
    }
  }
}
</style>
